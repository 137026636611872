import * as React from "react"
import { Link, graphql } from "gatsby"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/legal.scss"

// SEO
const title = "Terms and security – Timetastic"
const description = "Contractual questions including security and GDPR"

const LegalPage = ({ data }) => {
  return (
    <>
      <SEO title={title} useTitleTemplate={false} description={description} />
      <Layout pageTitle="Legal articles">
        <Header />
        <FooterCta />
        <main className="c-legal-main">
          <section className="c-section c-legal__container">
            <div className="u-inner u-inner--800 c-legal__index">
              <h1 className="h h3">Terms and security</h1>
              <p>
                We write all our terms, policies and fine print in plain
                English. You should find them all clear and concise, fair and
                reasonable.
              </p>
              <ul>
                {data.allMdx.nodes.map((node) => (
                  <li key={node.id}>
                    <Link to={`/legal/${node.slug}`}>
                      {node.frontmatter.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default LegalPage
